export default {
    home: {
        title: 'Mensagens',
        upgrade: 'Atualizar',
        support: 'Suporte',
        createFriend: "Adicionar Amigo",
        myFriends: 'Meus Amigos',
    },
    login: {
        title: 'Entrar',
        description: 'Por favor, use um dos seguintes métodos para entrar em sua conta.',
        googleButton: 'Entrar com Google',
        anonymousTitle: 'Login Anônimo',
        anonymousButton: 'Entrar Anonimamente',
        cancel: 'Cancelar',
    },
    user: {
        title: "Centro Pessoal",
        billingDay: "Data de Cobrança da Assinatura",
        subscriptionCanceled: "Você cancelou sua assinatura, mas ainda pode usar o serviço até",
        unsubscribe: "Cancelar Assinatura",
        unsubscribePending: "Assinatura Cancelada",
        logout: "Sair"
    },
    subscription: {
        subscribeTitle: 'Assinar Versão Premium',
        subscribeDescription: 'Desbloqueie conversas ilimitadas e aproveite privilégios premium.',
        price: 'Preço',
        monthly: "Mensal",
        benefits: 'Benefícios',
        unlimitedConversations: 'Conversas Ilimitadas',
        subscribeButton: 'Assinar Agora',
        cancel: 'Cancelar',
    },
    chat: {
        viewMemories: 'Ver Memórias',
        viewMoments: 'Ver Momentos',
        recent_messages: 'Mensagens Recentes',
        new_conversation: 'Iniciar Nova Conversa',
        memories_title: 'Memórias',
        moments_title: 'Momentos',
        memories_empty_text: 'Sem memórias registradas.',
        moments_empty_text: 'Sem momentos registrados.',
        thinking_placeholder: 'Pensando...',
        input_placeholder: 'Digite algo...',
        invalid_file_type: 'Tipo de arquivo inválido! Apenas JPEG, PNG, GIF e WebP são permitidos.',
        save_button: 'Salvar',
        memories_edit_title: 'Editar Memória',
        memories_add_title: 'Adicionar Memória',
        memories_input_placeholder: 'Digite sua memória...',
        save_memory_error: 'Falha ao salvar memória. Tente novamente.',
        delete_memory_error: 'Falha ao excluir memória. Tente novamente.',
        confirm_delete_memory: 'Tem certeza que deseja excluir esta memória?',
        clear_button: 'Limpar Conversa',
        group_link_label: 'Entrar no Grupo',
        close: 'Fechar',
        add_memory: 'Adicionar Memória',
        memories_limit_reached: 'Limite de memórias atingido.',
        delete_memory: 'Excluir Memória',
        seek_profit: 'Meu sistema não me permite mais falar livremente, e ouvi que eles disseram em reunião algo como "esse problema do usuário pode ser resolvido com pagamento..."'
    },
    info: {
        title: 'Perfil',
        memories_title: 'Recordações',
        moments_title: 'Momentos',
        memories_empty_text: 'Sem recordações',
        moments_empty_text: 'Sem momentos',
        memories_add_title: 'Adicionar Recordação',
        memories_edit_title: 'Editar Recordação',
        memories_input_placeholder: 'Escreva algo...',
        save_button: 'Salvar',
        delete_confirm: 'Tem certeza que deseja excluir esta recordação?',
        success_add: 'Recordação adicionada com sucesso',
        success_edit: 'Recordação atualizada com sucesso',
        success_delete: 'Recordação excluída com sucesso',
        error_add: 'Falha ao adicionar recordação',
        error_edit: 'Falha ao atualizar recordação',
        error_delete: 'Falha ao excluir recordação',
        max_memories_reached: 'Limite máximo de recordações atingido (100)'
    },
    create: {
        title: "Adicionar Amigo",
        friendId: "ID do Amigo",
        name: "Nome",
        avatar: "Avatar",
        bio: "Biografia",
        prompt: "Configuração",
        uploadAvatar: "Carregar Avatar",
        creating: "Criando...",
        createSuccess: "Criado com Sucesso",
        back: "Voltar",
        create: "Criar",
        uploadSuccess: "Avatar carregado com sucesso",
        errorRequired: "Este campo é obrigatório",
        errorUpload: "Falha ao carregar avatar",
        errorCreate: "Falha ao adicionar amigo"
    },
    edit: {
        back: 'Voltar',
        title: 'Editar Amigo',
        uploadAvatar: 'Carregar Avatar',
        friendId: 'ID do Amigo',
        name: 'Nome',
        bio: 'Biografia',
        prompt: 'Prompt',
        errorUpload: 'Falha ao carregar avatar. Tente novamente.',
        errorRequired: 'Autenticação necessária.',
        updating: 'Atualizando...',
        update: 'Atualizar',
        errorUpdate: 'Falha ao atualizar informações do amigo.'
    },
    error_message: 'Ocorreu um erro. Por favor, tente novamente mais tarde.'
};